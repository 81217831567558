
.footer {
    display: flex;
    justify-content: center;
    color: var(--white);
    margin: 16px;
    font-size: 12px;
    line-height: 14px;
    gap: 8px;
}

.footer .license {
    line-height: 18px;
}