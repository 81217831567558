* {
  margin: 0;
  padding: 0;
  outline: none;
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
  height: 100vh;
  overflow: hidden;
}

body {
  position: relative;
  height: 100%;
  margin: 0;
  font-feature-settings: 'kern';
  font-family: 'DinnoFont', sans-serif;
  color: var(--grey-text);
  background:  url("../public/assets/images/bg-body.jpg") center no-repeat;
  background-size: cover;
  overflow-y: auto;
}

a {
  background-color: transparent;
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

#root {
  height: 100vh;
  display: grid;
  place-items: center;
}
.text-center {
  text-align: center;
}

.text-bold {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

@font-face {
  font-family: 'DinnoFont';
  src: url('../public/assets/fonts/NotoSansArmenian-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --black: #000000;
  --blue: #216BFE;
  --blue-hover: #2266f4;
  --blue-border-hover: #1d5fe2;
  --blue-button-hover: #216BFE33;
  --blue-overlay: #102F83B2;
  --grey-border: #00000029;
  --grey-dashed-border: #4242421F;
  --grey-text: #424242;
  --grey-placeholder: #666666;
  --grey-toggler: #E1E4E8;
  --grey-disclaimer: #4A5B70;
  --error: #F3003A;
  --light-blue: #216BFE0F;
  --white: #FFFFFF;
}

.wrapper-container {
  max-width: 424px;
  width: 100%;
}

.wrapper-container.statement {
  max-width: 400px;
}


.form-container {
  width: calc(100% - 40px);
  min-height: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.form-container.error {
  min-height: initial;
  height: fit-content;
  padding: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--blue-overlay);

}
.react-dots-loader {
  margin-left: calc(50% - 30px);
  margin-top: calc(30% - 10px);
}

.title {
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: var(--black);
  margin-bottom: 8px;
}

.secondary-text {
  color: var(--grey-disclaimer);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.details >div:last-child {
  flex-grow: 1;
  text-align: right;
  display: flex;
}
.details >div:last-child::before {
    content:'';
    background: repeating-linear-gradient(to right, var(--grey-dashed-border), var(--grey-dashed-border) 5px, transparent 2px, transparent 9px);
    height: 1px;
    flex-grow: 1;
    display: inline-block;
    margin-top: 1em;
}

button[type="submit"] {
  width: 100%;
  background-color: var(--blue);
  color: white;
  padding: 9px;
  border: 1px solid  var(--blue);
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 24px;
}

button[type="submit"]:hover {
  background-color: var(--blue-hover);
  border-color: var(--blue-border-hover);
}

button[type="submit"]:disabled {
  opacity: 0.6;
  cursor: initial;
}

.form-group {
  margin-bottom: 16px;
}

.form-group.amount {
  background-color: var(--light-blue);
  padding: 12px;
  color: var(--blue);
  border-radius: 12px;
}

.form-group.anonymous {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid var(--grey-border);
  border-radius: 8px;
}

.toggle-container {
  width: 40px;
  height: 20px;
  border-radius: 24px;
  background-color: var(--grey-toggler);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  position: relative;
  margin-top: 4px;
}

.toggle-container.anonymous {
  background-color:  var(--blue);
}

.anonymous .secondary-text {
  margin: 2px 0 0 8px;
  text-align: start;
}

.anonymous .secondary-text.disclaimer{
  font-size: 12px;
  line-height: 16px;
  margin-top: 0;
}

.toggleButton {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--white);
  position: absolute;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.toggleButton.anonymous {
  transform: translateX(20px)
}
.quantity {
  background: var(--white);
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin: 8px 0;
}
.counter {
  color: var(--blue);
  background: var(--white);
  padding: 12px 16px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
}
.counter:hover {
  background-color: var(--blue-button-hover);
}
.counter[disabled] {
  opacity: 0.6;
}
.counter[disabled]:hover {
  background: none;
  cursor: initial;
}
.quantity input {
  background: var(--white);
  line-height: 16px;
  padding: 15px;
  text-align: center;
  border: none;
  outline: none;
  color: var(--blue);
}
.quantity input:focus {
  border: none;
  box-shadow: none;
}

label {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

input {
  width: calc(100% - 20px);
  padding: 8px;
  border: 1px solid var(--grey-border);
  border-radius: 8px;
  line-height: 24px;
}
.amount.isFlexible {
  position: relative;
}

.amount input{
  color: var(--blue);
  font-weight: 700;
}
.currency {
  color: var(--grey-text);
  position: absolute;
  right: 24px;
  top: 46px;
}

input.error {
  border-color: var(--error);
}

input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(33, 107, 254, 0.08);
}

.options-container {
  display: flex;
  margin-bottom: 20px;
  gap: 8px
}

.option {
  width: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 4px;
  border-radius: 8px;
  border: 1px solid var(--grey-border);
  cursor: pointer;
  position: relative;
  font-size: 12px;
  line-height: 20px;
}
.option.selected, .option:hover {
  border-color: var(--blue);
}
.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center
}

.check-icon {
  position: absolute;
  top: -5px;
  right: -17px
}

.error-message {
  font-size: 12px;
  line-height: 20px;
  color: var(--error);
}

@media (max-width: 576px) {

  #root {
    height: initial;
  }
  .wrapper-container {
    padding-top: 4px;
  }

  .react-dots-loader {
    margin-top: calc(50%);
  }
}