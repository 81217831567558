
.language-container {
    position: relative;
    display: inline-block;
}
.selectedLanguage {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
}

.selectedLanguage img {
    margin-right: 8px;
}
.dropdown {
    position: absolute;
    top: 32px;
    left: 6px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 #72717180;
    overflow: hidden;
    z-index: 1
}

.dropdown >div {
    height: 20px;
    padding: 8px;
    cursor: pointer;
}

.anchor {
    width: 5px;
    height: 5px;
    border-right: 2px solid var(--white);
    border-bottom: 2px solid var(--white);
    rotate: 45deg;
}